<template>
  <v-container fluid style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <h2 class="mb-3">{{ $t('dashboard.title') }}</h2>
    <v-row>
      <v-col cols="12" lg="8">
        <ConversationLastList />
        <NoticeLastList v-if="mxPermission('notice-read')" />
      </v-col>
      <v-col cols="12" lg="4">
        <div style="position: sticky; top: 60px">
          <v-card class="pa-6 pt-1 pb-6">
            <v-card-title class="ml-n4">{{ $t('dashboard.employees_online') }}</v-card-title>
            <!--      <v-divider></v-divider>-->
            <div style="height: calc(100vh - 220px); overflow-y: auto; padding: 0 20px 0 0;">
              <v-row>
                <v-col cols="12" v-for="(item, index) in users" :key="index" class="mb-n2">
                  <v-card elevation="1" >
                    <v-list-item>
                      <v-list-item-avatar rounded size="46">
                        <v-img v-if="item.avatar !== null" :src="options.avatar_url + '' + item.avatar"></v-img>
                        <v-avatar rounded v-else color="primary" size="46">
                          {{ item.first_name[0] }}{{ item.last_name[0] }}
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                        <v-list-item-subtitle class="mt-1" v-html="authorizedDate(item.authorized.created_at)"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NoticeLastList from "../modules/notice/components/NoticeLastList";
import ConversationLastList from "@/components/Customer/Crm/Company/_conversation/ConversationLastList";

export default {
  components: { NoticeLastList, ConversationLastList },
  data() {
    return {
      user: {},
      users: [],
      preloader: true,
    }
  },
  watch: {
    '$store.getters.employeesCrmDashboard'() {
      this.users = this.$store.getters.employeesCrmDashboard
    }
  },
  mounted() {
    this.users = this.$store.getters.employeesCrmDashboard

    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  methods: {
    mxPermission(permission) {
      return this.mxware.can(permission)
    },
    authorizedDate(date) {
      const arr = date.split(' ')
      return `<span class="authorized_date__date">${arr[0]}</span><span class="authorized_date__hour">${arr[1]}</span>`
    },
    webSocketLogin() {
      window.Echo.channel('channel-auth').listen('Login', (e) => {
        this.$store.commit('SET_EMPLOYEE_CRM_DASHBOARD', this.$store.getters.employeesCrm.data.find(user => user.id === e.id))
        //this.users = this.$store.getters.employeesCrmDashboard
      })
    }
  }
}
</script>


<style lang="scss">
.authorized_date {
  &__date {
    background: #e8e8e8;
    padding: 1px 6px 1px 6px;
    border-radius: 2px 0 0 2px;
    font-size: 13px;
  }

  &__hour {
    background: #4c4335;
    padding: 1px 6px 1px 6px;
    border-radius: 0 2px 2px 0;
    font-size: 13px;
    color: #ffffff;
  }
}
</style>
