<template>
  <v-card class="mb-6">
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('conversation.title_last') }}
    </v-card-title>
    <v-card-text>
      <template v-if="!preloader">
        <v-simple-table fixed-header dense height="280px">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">{{ $t('conversation.table.company') }}</th>
              <th class="text-left">{{ $t('conversation.table.tag') }}</th>
              <th class="text-left">{{ $t('conversation.table.employee') }}</th>
              <th class="text-left">{{ $t('conversation.table.contact_person') }}</th>
              <th class="text-left">{{ $t('conversation.table.date') }}</th>
              <th class="text-left">{{ $t('conversation.table.description') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="conversation in conversations" :key="conversation.id">
              <td>
                <div>
                  <router-link :to="{name: 'crm.customers.show', params: {id: getCompany(conversation.company_id).customer_id }}">
                  <span style="white-space: nowrap">
                    {{ getCompany(conversation.company_id).company_name }}
                  </span>
                  </router-link>
                </div>
              </td>
              <td>
              <span :style="`background: ${conversation.conversation_on.color}; color: #fff; padding: 2px 13px; font-size: 11px; border-radius: 2px; white-space: nowrap`">
                {{ conversation.conversation_on.display_text }}
              </span>
              </td>
              <td v-html="strWrap(conversation.employee)"></td>
              <td v-html="strWrap(conversation.contact_person)"></td>
              <td style="white-space: nowrap">{{ getDate(conversation.date) }}</td>
              <td v-html="brs(conversation.description)"></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <template v-else>
        <div style="height: 220px;" class="d-flex align-center justify-center">
          <img src="/assets/images/spiner-b.gif" alt="preloader">
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "ConversationLastList",
  data() {
    return {
      companies: [],
      conversations: [],
      preloader: true,
    }
  },
  async mounted() {
    await this.getConversations()
  },
  methods: {
    getCompany(company_id) {
      return this.companies.find(company => company.id === company_id)
    },
    async getConversations() {
      await axios.get(`/customer/companies/all`).then(response => {
        this.companies = response.data.data
        setTimeout(() => {
          this.preloader = false
        }, 300)
      })

      await axios.get(`/customer/companies/conversation-history/last-conversations`).then(response => {
        this.conversations = response.data.data
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            console.log('[ERROR 500]', error);
            setTimeout(this.getConversations, 30)
            break;
        }
      })
    },
    getDate(data) {
      return data.substr(0, 10).split('-').reverse().join('.') + ' ' + data.substr(11)
    },
    brs(text) {
      if (text !== null) {
        return text.replace(/\n/g, '<br />')
      }
      return text
    },
    strWrap(text) {
      if (text !== null) {
        return `<small>${text.replace(' ', '<br />')}</small>`
      }
      return text
    },
  }
}
</script>

<style lang="scss">
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 36px !important;
  color: #3b3b3b;
}

small {
  //font-size: 12px;
  font-weight: 700;
  & > br {
    display: block;
    margin-bottom: -6px;
    font-size: 2px;
    line-height: 2px;
  }
}
</style>
